<template>
  <div>
    <my-loader :loader="loader"></my-loader>
    <div class="align-center"></div>

    <div v-if="tax_deduction_list[0]">
      <p class="text-2xl mb-6">Налоговый вычет | Справка № {{ $route.params.id }}</p>
      <div class="mb-5">
        <router-link to="/tax_deduction"> Назад к списку справок </router-link>
      </div>

      <v-card>
        <v-card-title class="mb-5">Справка № {{ $route.params.id }} </v-card-title>
        <v-card-subtitle>
          Статус
          <v-chip class="font-weight-medium" :color="statusColor[tax_deduction_list[0].complete]" small>
            {{ status[tax_deduction_list[0].complete] }}
          </v-chip>
        </v-card-subtitle>
        <v-card-subtitle>
          <strong>О клиенте</strong>
        </v-card-subtitle>
        <v-card-text>
          <ul>
            <li>Клиент: {{ tax_deduction_list[0].user_name }}</li>
            <li>Телефон: {{ tax_deduction_list[0].user_phone }}</li>
            <li>Email: {{ tax_deduction_list[0].user_email }}</li>
          </ul>
        </v-card-text>
        <template v-if="!data_edit">
          <v-card-subtitle><strong>Информация для справки</strong></v-card-subtitle>
          <v-card-text>
            <div v-if="tax_deduction_list[0].child_fio">
              <strong>Данные клиента</strong>
            </div>
            <ul>
              <li>ФИО: {{ tax_deduction_list[0].fio }}</li>
              <li>ИНН: {{ tax_deduction_list[0].inn }}</li>
              <li>Дата рождения: {{ tax_deduction_list[0].birthday }}</li>
            </ul>
          </v-card-text>
          <v-card-text v-if="tax_deduction_list[0].child_fio">
            <div><strong>Данные ребенка</strong></div>
            <ul>
              <li>ФИО: {{ tax_deduction_list[0].child_fio }}</li>
              <li>ИНН: {{ tax_deduction_list[0].child_inn }}</li>
              <li>Дата рождения: {{ tax_deduction_list[0].child_birthday }}</li>
            </ul>
          </v-card-text>
          <v-card-text>
            <v-btn color="primary" class="mt-2" small @click="data_edit = true"> Редактировать </v-btn>
          </v-card-text>
        </template>
        <template v-if="data_edit">
          <div style="background-color: rgb(237 237 237); padding: 10px 0">
            <v-card-subtitle><strong>Информация для справки</strong></v-card-subtitle>
            <v-card-text>
              <div v-if="tax_deduction_list[0].child_fio">
                <strong>Данные клиента</strong>
              </div>
              <div>
                <v-text-field v-model="tax_deduction_list[0].fio" label="ФИО"></v-text-field>
              </div>
              <div>
                <v-text-field
                  v-model="tax_deduction_list[0].inn"
                  counter="10"
                  maxlength="10"
                  label="ИНН"
                ></v-text-field>
              </div>
              <div>
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tax_deduction_list[0].birthday_iso"
                      label="Дата рождения"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="tax_deduction_list[0].birthday_iso" @input="menu1 = false"></v-date-picker>
                </v-menu>
              </div>
            </v-card-text>
            <v-card-text v-if="tax_deduction_list[0].child_fio">
              <div><strong>Данные ребенка</strong></div>
              <div>
                <v-text-field v-model="tax_deduction_list[0].child_fio" label="ФИО ребенка"></v-text-field>
              </div>
              <div>
                <v-text-field
                  v-model="tax_deduction_list[0].child_inn"
                  counter="10"
                  maxlength="10"
                  label="ИНН ребенка"
                ></v-text-field>
              </div>
              <div>
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="tax_deduction_list[0].child_birthday_iso"
                      label="Дата рождения ребенка"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="tax_deduction_list[0].child_birthday_iso"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-card-text>
            <v-card-text>
              <v-btn :disabled="loading" color="primary" class="mt-1" small @click="updateTaxDeductionCert">
                Сохранить
              </v-btn>
            </v-card-text>
          </div>
        </template>
        <v-card-subtitle class="mt-5"><strong>Платежи</strong></v-card-subtitle>
        <v-card-text>
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-left">№</th>
                <th class="text-left">Дата</th>
                <th class="text-left">Сумма</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in tax_deduction_list[0].orders" :key="item.id">
                <td>{{ index + 1 }}</td>
                <td>{{ item.created_at }}</td>
                <td>{{ item.sum | format }} ₽</td>
              </tr>
              <tr>
                <td colspan="3">Сумма: {{ tax_deduction_list[0].sum | format }} ₽</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>

        <v-card-text>
          <label for="cert_scan">Загрузить скан справки</label> <br />
          <input type="file" id="cert_scan" @change="UploadFile" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="primary" @click="downloadCert()"> Скачать PDF</v-btn>
          <v-btn v-if="tax_deduction_list[0].uploaded_files[0]" color="primary" @click="downloadScan()">
            Скачать скан справки</v-btn
          >
          <v-btn color="success" @click="completeTaxDeduction()" v-if="!tax_deduction_list[0].complete">
            Выдать справку
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api'
import MyLoader from '@/components/MyLoader.vue'
import axios from 'axios'

export default {
  components: { MyLoader },
  filters: {
    format: val => `${val}`.replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 '),
  },
  data() {
    return {
      errorMessages: '',
      active_project: [],
      tax_deduction_list: [],
      search: null,
      tax_deduction_popup: false,
      menu1: false,
      menu2: false,
      data_edit: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      loading: false,
      loader: false,
      headers: [
        { text: 'Номер', value: 'id', align: 'start' },
        { text: 'Клиент', value: 'user_name' },
        { text: 'Телефон', value: 'user_phone' },
        { text: 'Email', value: 'user_email' },
        { text: 'Сумма', value: 'sum' },
        { text: 'Статус выдачи', value: 'complete' },
        { text: 'Дата создания запроса', value: 'created_at' },
        { text: 'Действие', value: 'action' },
      ],
      valid: '',
      status: {
        true: 'выдана',
        false: 'не выдана',
      },
      statusColor: {
        false: 'info',
        true: 'success',
      },
      fileUpload: [],
    }
  },
  watch: {
    with_bill() {
      if (!this.with_bill) {
        this.cert_sum = null
      }
    },
  },
  async created() {
    this.$watch(() => this.$route.params)
  },
  async mounted() {
    this.getTaxDeduction()
  },
  methods: {
    downloadScan() {
      window.open('https://lk.xbadm.ru' + this.tax_deduction_list[0].uploaded_files[0].file_url, '_blank')
    },
    downloadCert() {
      window.open('https://lk.xbadm.ru/api/v1/sales/tax_deduction_pdf?cert_id=' + this.$route.params.id, '_blank')
    },
    UploadFile(file) {
      const formData = new FormData()
      formData.append('cert_id', this.$route.params.id)
      formData.append('file', file.target.files[0])
      const headers = { 'Content-Type': 'multipart/form-data' }
      axios
        .post('/sales/upload_tax_deduction_file', formData, { headers })
        .then(res => {
          this.getTaxDeduction()
          res.data.files
          res.status
          alert(res.data.success)
        })
        .catch(function (error) {
          alert(error)
        })
    },
    completeTaxDeduction() {
      if (confirm('Вы уверены что хотите выдать справку?')) {
        try {
          Api.completeTaxDeduction({
            tax_deduction_cert_id: this.$route.params.id,
          }).then(
            (event => {
              this.getTaxDeduction()
            }).bind(this),
          )
        } catch (error) {
          console.log(error)
        }
      }
    },
    updateTaxDeductionCert() {
      this.loading = true
      try {
        Api.updateTaxDeductionCert({
          cert_id: this.$route.params.id,
          fio: this.tax_deduction_list[0].fio,
          inn: this.tax_deduction_list[0].inn,
          birthday: this.tax_deduction_list[0].birthday_iso,
          child_fio: this.tax_deduction_list[0].child_fio,
          child_inn: this.tax_deduction_list[0].child_inn,
          child_birthday: this.tax_deduction_list[0].child_birthday_iso,
        }).then(
          (event => {
            this.$set(this, 'loading', false)
            this.$set(this, 'data_edit', false)
            this.$set(this, 'tax_deduction_list[0]', event.data)
          }).bind(this),
        )
      } catch (error) {
        console.log(error)
      }
    },
    getTaxDeduction() {
      this.loading = true
      try {
        Api.getTaxDeduction({
          filters: {
            id: this.$route.params.id,
          },
        }).then(
          (event => {
            this.$set(this, 'loading', false)
            this.$set(this, 'tax_deduction_list', event.data)
          }).bind(this),
        )
      } catch (error) {
        console.log(error)
      }
    },
    reset() {
      this.$refs.form.reset()
    },
  },
}
</script>

<style scoped>
.filters {
  padding: 10px;
  background-color: #e6e6eb;
  border-radius: 5px;
  margin-bottom: 20px;
}

.href {
  text-decoration: underline;
  color: #2f65ae;
  cursor: pointer;
}

.label {
  font-size: 13px;
}

.mx-input {
  height: 48px;
}

.btn_list {
  list-style: none;
  margin: 0 0 20px 0;
  padding: 0;
}
</style>
